<template>
  <div>
    <header-portrate />
    <exports-print ref="exportbar" v-if="$store.state.group_id != 9" />
    <div>
      <bread-crumbs :items="items"></bread-crumbs>

      <tabs-comp :tabs="tabs" />
      <v-row
        class="mainrow m-2"
        :style="`direction:` + lang.dir + `;`"
      >
        <v-row>
          
          <v-col cols="12" md="12" sm="12" class="text-end">
            <b-button
              v-b-toggle.add_customer
              class="btn-sm btn btn-success"
              style="
                width: 150px;
                background: transparent;
                border: none;
                color: #000;
              "
              id="multiply-button"
              @click="resetCustom()"
            >
              <div style="width: 150px" :class="`multiply-button-content-` + lang.lalgin">
                {{ lang.add_customer }}
              </div>
            </b-button>
          </v-col>

          <v-col cols="12" :style="`direction:` + lang.dir + `;`">
            <div class="ListOnMobile">
                <div class="inlineClient" v-for="(item,index) in tablerows" :key="index" :style="``">
                    <div style="width:calc(100% - 50px)">
                        <div style="white-space:nowrap">{{ item.company_name == '' ? item.full_name : item.company_name }}</div>
                        <div class="showCases">
                            <div :style="`width:30%;border:1px solid #000;padding:5px;background:#eee;color:#000;text-align:center;`">{{ getIndustries[item.industrial == '' ? 0 : item.industrial].name }}</div>
                            <div :style="`width:30%;border:1px solid #000;padding:5px;background:#eee;color:#000;text-align:center;`">{{ item.city }}</div>
                            <div :style="`width:30%;border:1px solid #000;padding:5px;background:#eee;color:#000;text-align:center;`">{{ item.nationality }}</div>
                        </div>
                    </div>
                    <div>
                        <div style="text-align:center;padding:5px 2px;background:blue;color:#fff;margin-top:5px;border-radius:5px;" @click="upCusto(item)" v-b-toggle.add_customer>{{ lang.update }}</div>
                        <div style="text-align:center;padding:5px 2px;background:darkred;color:#fff;margin-top:5px;border-radius:5px;"><a :href="`tel:${item.mobile1}`" style="text-decoration:none;color:#fff !important">{{ lang.call }}</a></div>
                        <div style="text-align:center;padding:5px 2px;background:darkgreen;color:#fff;margin-top:5px;border-radius:5px;" @click="sendWhats(item)"  v-b-toggle.sendWhatsMobile>{{ lang.whatsapp }}</div>
                    </div>
                </div>
            </div>
          </v-col>
          <v-col cols="12" style="margin-bottom: 25px; margin-top: -15px">
            <v-container>
              <div class="text-center pt-2">
                <v-pagination
                  v-model="page"
                  value="15"
                  :length="pageCount"
                  color="#000"
                  @input="getNext(page)"
                ></v-pagination>
              </div>
            </v-container>
          </v-col>
        </v-row>
      </v-row>
      <add-customer ref="addCustomer" />
      <sendWhatsMobile ref="sendWhatsMobile" />

      <!-- <UpdateCustomer ref="updatecustomer" /> -->
    </div>
    <Footer />
  </div>
</template>

<script>
import BreadCrumbs from "@/components/breadCrumbs.vue";
import axios from "axios";
import AddCustomer from "@/components/addCustomer.vue";
import sendWhatsMobile from "@/components/sendWhatsMobile.vue";
import TabsComp from "@/components/tabsComp.vue";
import HeaderPortrate from "@/components/Header-Portrate.vue";
import Footer from "@/components/Footer.vue";
import ExportsPrint from "@/components/exports-print.vue";
export default {
  name: "clientlist",
  components: {
    BreadCrumbs,
    AddCustomer,
    TabsComp,
    HeaderPortrate,
    Footer,
    ExportsPrint,
    sendWhatsMobile
  },
  data() {
    return {
      industrial: 0,
      mobile: "",
      customer_name: "",
      city: "",
      cpage: 0,
      openQuot: false,

      page: 1,
      pageCount: 0,
      search: "",
      tablerows: [],
      opselected: {},
      ifpselected: {},
      invorquot: 0,
      tab1: 1,
      tab2: 2,
      tab3: 3,
      ccustomer: {},
      dustrial: [],
    };
  },
  computed: {
    
    lang: function () {
      if (this.$cookies.get(this.$store.state.LangCooki) != null) {
        if (this.$cookies.get(this.$store.state.LangCooki) == "en") {
          return this.$store.state.lang.en;
        } else {
          return this.$store.state.lang.ar;
        }
      } else if (this.$store.state.deflang == "en") {
        return this.$store.state.lang.en;
      } else {
        return this.$store.state.lang.ar;
      }
    },
    getIndustries: function() {
        let t = [
            {
                name: ' - ',
                style: ''
            }
        ];
        let name = ' - ';
        let style = '';
        for(let i=0;i<this.dustrial.length;i++){
            name = this.dustrial[i][`name_${this.lang.langname}`];
            style = "background:"+this.dustrial[i].bgColor+" !important;color:"+this.dustrial[i].color+";"
            t.push( {
                name: name,
                style: style
            })
        }
        

        return t;
    },
    items: function () {
      return {
        text: this.lang.customers,
        disabled: true,
        href: "/clientList",
      };
    },
    industrials: function () {
      let t = [{ text: this.lang.industrial, value: 0 }];
      for (let i = 0; i < this.dustrial.length; i++) {
        t.push({
          text: this.dustrial[i][`name_` + this.lang.langname],
          value: this.dustrial[i].value,
        });
      }
      return t;
    },
    currentCus: function () {
      if (this.invorquot == 1) {
        return {
          id: this.opselected.id,
          full_name: this.opselected.full_name,
          mobile: this.opselected.mobile1,
          company: {
            fullname: this.opselected.company_name,
            vatid: this.opselected.company_vatid,
          },
        };
      } else {
        return {
          id: this.ifpselected.id,
          full_name: this.ifpselected.full_name,
          mobile: this.ifpselected.mobile1,
          company: {
            fullname: this.ifpselected.company_name,
            vatid: this.ifpselected.company_vatid,
          },
        };
      }
    },
    headers: function () {
      let headers = [
        {
          text: this.lang.customerid,
          align: "center",
          filterable: true,
          value: "customerid",
          sortable: false,
          class: "backBlack",
        },

        {
          text: this.lang.mobile,
          align: "center",
          filterable: true,
          value: "mobile",
          sortable: false,
          class: "backBlack",
        },
        // {
        //     text: this.lang.additional_mobile, align: 'center',
        //     filterable: true, value: 'mobile1',
        //     sortable: false,
        //     class: 'backBlack'
        // },
        {
          text: this.lang.customer_name,
          align: "center",
          filterable: true,
          value: "full_name",
          sortable: true,
          class: "backBlack",
        },
        {
          text: this.lang.customer_type,
          align: "center",
          filterable: true,
          value: "customer_type",
          sortable: false,
          class: "backBlack",
        },
        {
          text: this.lang.company_name,
          align: "center",
          filterable: true,
          value: "company_name",
          sortable: false,
          class: "backBlack",
        },
        {
          text: this.lang.city,
          align: "center",
          filterable: true,
          value: "city",
          sortable: false,
          class: "backBlack",
        },
        // {
        //     text: this.lang.industry, align: 'center',
        //     filterable: true, value: 'city',
        //     sortable: false,
        //     class: 'backBlack'
        // },

        // {
        //     text: 'حالة الفواتير', align: 'center',
        //     filterable: true, value: 'vatid',
        //     sortable: false,
        //     class: 'backBlack'
        // },
        // {
        //     text: 'المهمة', align: 'center',
        //     filterable: true, value: 'vatid',
        //     sortable: false,
        //     class: 'backBlack'
        // },
        // {
        //     text: 'الملاحظة', align: 'center',
        //     filterable: true, value: 'vatid',
        //     sortable: false,
        //     class: 'backBlack'
        // },
        // {
        //     text: 'الموعد القادم', align: 'center',
        //     filterable: true, value: 'vatid',
        //     sortable: false,
        //     class: 'backBlack'
        // },

        // {
        //     text: this.lang.add_invoice, align: 'center',
        //     filterable: false,
        //     sortable: false,
        //     class: 'backBlack',
        //     printRemove: 0
        // },

        // {
        //     text: 'الضمان', align: 'center',
        //     filterable: true, value: 'tasks',
        //     sortable: false,
        //     class: 'backBlack'
        // },
        // {
        //     text: this.lang.tasks, align: 'center',
        //     filterable: true, value: 'tasks',
        //     sortable: false,
        //     class: 'backBlack'
        // },
        // {
        //     text: this.lang.total, align: 'center',
        //     filterable: true, value: 'vatid',
        //     sortable: false,
        //     class: 'backBlack'
        // },
        // {
        //     text: this.lang.remain, align: 'center',
        //     filterable: true, value: 'vatid',
        //     sortable: false,
        //     class: 'backBlack'
        // },
        // {
        //     text: this.lang.client_statement, align: 'center',
        //     filterable: false,
        //     sortable: false,
        //     class: 'backBlack',
        //     printRemove: 1
        // },
        // {
        //     text: this.lang.remain, align: 'center',
        //     filterable: true, value: 'vatid',
        //     sortable: false,
        //     class: 'backBlack'
        // },
        // {
        //     text: this.lang.status, align: 'center',
        //     filterable: true, value: 'vatid',
        //     sortable: false,
        //     class: 'backBlack'
        // },
        {
          text: this.lang.view,
          align: "center",
          filterable: false,
          sortable: false,
          class: "backBlack",
          printRemove: 0,
        },
      ];
      return headers;
    },
    tabs: function () {
      const menu = this.$store.getters["state"].menu;
      const license = this.$store.getters["state"].licenseType.activemenu;
      const alicense = this.$store.getters["state"].licenseType;
      let currentMenu = menu[license];
      if (!license) {
        return false;
      }
      let cMenu = { main: {}, sub: [] };
      Object.values(currentMenu).forEach((value) => {
        const cM = localStorage.getItem("currentMenu");
        if (value.main.index == cM) {
          cMenu = value.sub;
        } else if (cM == null && value.main.index == 1) {
          cMenu = value.sub;
        }
      });

      let t = [];
      Object.values(cMenu).forEach((value) => {
        if (this.$route.path == value.href) {
          value.class = "mytab";
        } else {
          value.class = "mytab4";
        }
        if (this.lang.langname == "ar") {
          value.name = value.arname;
        } else {
          value.name = value.enname;
        }
        for (let i = 0; i < value.groupid.length; i++) {
          if (value.groupid[i] == this.$store.getters["state"].group_id) {
            if (alicense[value.perms[0]] || value.perms[0] == "any") t.push(value);
          }
        }
      });

      return t;
    },
  },
  methods: {
    sendWhats(item){
      this.$refs.sendWhatsMobile.reset()
      this.$refs.sendWhatsMobile.mobile = item.mobile1
      this.$refs.sendWhatsMobile.customerid = item.id
    },
    getStaticOption(phrase) {
      const post = new FormData();
      post.append("type", "getStaticOptions");
      
      post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
      post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append("data[phrase]", phrase);

      axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
        // // console.log(response.data);
        this.dustrial = response.data.results.data;
      });
    },
    getCustomer() {
      const post = new FormData();
      post.append("type", "getClients");
      post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
      post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append("data[mobile]", this.mobile);
      post.append("data[customer_name]", this.customer_name);
      post.append("data[city]", this.city);
      post.append("data[industrial]", this.industrial);

      axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
        // // console.log(response.data);
        this.pageCount = parseInt(parseInt(response.data.results.allres) / 15) + 1;
        this.tablerows = response.data.results.data;
      });
    },
    custType(id) {
      if (id == 1) return this.lang.individual_client;
      else if (id == 2) return this.lang.company_clinet;
      else if (id == 3) return this.lang.government_clinet;
    },
    preparedata() {
      let exdata = {
        list: "clientList",
        title: this.lang.customers,
        data: [],
      };
      for (let i = 0; i < this.tablerows.length; i++) {
        exdata.data.push(this.tablerows[i]);
      }
      // // console.log(exdata);
      this.$refs.exportbar.exportdata = exdata;
      this.$refs.exportbar.sdate = "";
      this.$refs.exportbar.edate = "";

      this.$refs.exportbar.exporttitle = "";
      this.$refs.exportbar.palte_number = "";
      this.$refs.exportbar.contact_number = "";
    },
    getCurrentCards() {
      //
    },
    resetCustom(){
        this.$refs.addCustomer.reset();
        this.$refs.addCustomer.editMode=false;
    },
    upCusto(item) {
        this.$refs.addCustomer.updateCustomer(item);
        this.$refs.addCustomer.editMode=true;
      // console.log("item" ,item);
    //   this.$refs.updatecustomer.customers = item;
    //   this.$refs.updatecustomer.customers.mobile = item.mobile1;
    //   this.$refs.updatecustomer.customers.mobile1 = item.mobile2;
    //   this.$refs.updatecustomer.customers.customer_type = item.customer_type;

    //   this.$refs.updatecustomer.firstUpdate();
    //   this.$refs.updatecustomer.changeCusType(parseInt(item.customer_type) - 1);
    },
    quotOP(item) {
      this.$refs.addQoutFloat.company = {
        full_name: item.fullname,
        mobile: item.mobile,
      };
      this.$refs.addQoutFloat.customer.id = item.id;
      this.$refs.addQoutFloat.customer.mobile = item.mobile1;
      this.$refs.addQoutFloat.customer.full_name = item.full_name;
      this.$refs.addQoutFloat.company.fullname = item.company_name;
      this.$refs.addQoutFloat.company.vatid = item.company_vatid;
    },
    InvOP(item) {
      this.$refs.addinvoices.customer.mobile = item.mobile1;
      this.$refs.addinvoices.getName();

      if (item.company_name != "") {
        this.$refs.addinvoices.customer.cctype = 2;
        this.$refs.addinvoices.changeCtype();
      } else {
        this.$refs.addinvoices.customer.cctype = 1;
        this.$refs.addinvoices.changeCtype();
      }
    },
    getCust(id){
        this.$refs.customerRef.customerid = id;
        this.$refs.customerRef.getCustomerInfo();
    },
    getCus(id) {
      this.$refs.convertClient.customerid = id;
      this.$refs.convertClient.expected_amount = "";
    },
    getNext(page) {
      this.cpage = parseInt(page) - 1;
      this.getClients();
    },
    getClients() {
      const post = new FormData();
      post.append("type", "getClients");
      post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
      post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append("data[all]", 1);
      post.append("data[cpage]", this.cpage);

      axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
        // // console.log(response.data);
        this.pageCount = parseInt(parseInt(response.data.results.allres) / 15) + 1;
        this.tablerows = response.data.results.data;
      });
    },
    getStatement(item) {
      // this.$router.push({path: '/client-statement/'+item.id})
      let classid = parseFloat(item.customerid);
      // if(parseFloat(item.customerid) < 6000){
      //     classid = parseFloat(item.customerid) + parseFloat(6000);
      // }
      this.$refs.clientStatments.accountn.full_name = item.full_name;
      this.$refs.clientStatments.accountn.customerid = classid;
      this.$refs.clientStatments.getReport();
      this.$refs.clientStatments.getReport();
    },
  },
  created() {
    this.getClients();
    this.getStaticOption("inDustrial");
  },
};
</script>
<style>
.ListOnMobile{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  align-items: stretch;
}
.inlineClient{
    background:#ccc;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-inline-end: 5px;
    width:calc((100% - 30px) / 5);
    border:1px solid #000;
    border-radius: 3px;
    padding:5px;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
}
.inlineClient *{
  font-size:.7rem;
}
.showCases{
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
}
.ListOnMobile:first-child{
    margin-inline-start:5px;
}
.ListOnMobile:last-child{
    margin-inline-end:5px;
}
.myDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
@media (max-width: 768px) {
    .inlineClient{
        width: 100%;
    }
}
</style>
