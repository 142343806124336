<template>
    <div class="px-3 py-2">
        <v-form
            ref="form"
            lazy-validation
        >
        <b-sidebar id="sendWhatsMobile" aria-labelledby="sidebar-no-header-title" width="900px" no-header backdrop style="direction:ltr" right title="اضافة عميل" shadow >
        <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div v-if="$store.state.group_id != 9" style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                <span></span>
                </div>
                <div v-else>
                    &nbsp;
                </div>
                <div @click="hide" id="sendWhatMob" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                <span>{{lang.close}}</span>
            </div>
        </div>
        <v-divider></v-divider>
        <div class="px-3 py-2" style="direction:rtl">
            
            <div class="buttonRow">
                <div class="btnShow" v-for="(item,index) in sendMenu" :key="index" @click="sendIt(item.id)">
                    <div class="btnHere">{{ item.lang }} - {{ item.title }}</div>
                </div>
            </div>
            
        </div>
        </template>
        
        </b-sidebar>
        </v-form>
        <vue-snotify></vue-snotify>
      </div>
</template>


<script>
import axios from 'axios';
import {SnotifyPosition} from 'vue-snotify';
export default{
    data: () => ({
        customerid: 0,
        mobile: '',
        sendMenu: []
    }),
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    }, 
    created(){
        this.getMenu();
    },
    methods: {
        sendIt(id){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "sendWhat");
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[id]", id);
            post.append("data[customerid]", this.customerid);
            post.append("data[mobile]", this.mobile);
            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                this.sendMenu = response.data.results.data;
            });
        },
        getMenu(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "getSentMenu");
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[all]", 0);
            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                this.sendMenu = response.data.results.data;
            });
        },
        reset() {
            this.customerid = 0;
            this.mobile = '';
        },
        
        getStaticid(id) {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type", "getStaticOptions");
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[phrase]", id);
            // post.append("data[phrase]", "convertProducts");
            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                // // console.log("i am here",response.data)
                if(id == 'convertProducts')
                    this.optionsS = response.data.results.data;
                else if(id == 'customerSource')
                    this.sources = response.data.results.data
                else if(id == 'inDustrial')
                    this.inDusts = response.data.results.data
                // // console.log("i am here",this.optionsS);
            });
        },
        
    },
}
</script>
<style scoped>
.buttonRow{
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: stretch;
}
.btnShow{
    width:100%;
    padding:3px;
}
.btnHere{
    padding:3px;
    margin:auto;
    background:#e3e3e3;
    border: 1px solid #000;
    border-radius: 5px;
    text-align:center;
    cursor:pointer;
}
.hideIcon {
    display:none;
}
@media only screen and (max-width = 1024px){
    .buttonRow{
        flex-direction: column;
    }
}
</style>